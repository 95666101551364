<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet.heat';
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import axios from "axios";
import Swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Définir des zones sensibles",
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      map: null,
      showBtn: true,
      heatmapLayer: null,
      incidents: {},
      filterIncidents: {},
      zoneData: {},
      title: "Définir des zones sensibles",
      items: [
        {
          text: "Paramétrage",
          href: "/settings",
        },
        {
          text: "Zones sensibles",
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      spanView: false,
      params: {
        libelle: "",
      },
    };
  },
  validations: {
    params: {
      libelle: {
        required: helpers.withMessage("Libelle is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    async initializeMap() {
      this.map = L.map('map').setView([6.1319, 1.2225], 12);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: ''
      }).addTo(this.map);

      this.zoneData = JSON.parse(localStorage.getItem('zoneS'));

      const coordinates = this.zoneData?.zone?.coordinates[0]?.map(coord => L.latLng(coord[1], coord[0]));

      const polygonLayer = L.polygon(coordinates, {
        color: 'blue',
        fillColor: 'white',
        fillOpacity: 0.3
      }).addTo(this.map);

      this.map.fitBounds(polygonLayer.getBounds());
    },
    getZoneDetails() {
      this.zoneData = JSON.parse(localStorage.getItem('zoneS'));
    },
    async updateZone() {
      const that = this;
      this.spanView = true;
      const formData = new FormData();
      formData.append('id', this.zoneData.id);
      formData.append('name', this.zoneData.name);
      formData.append('message', this.zoneData.message);
      try {
        const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/dangerarea/dangerarea/`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        console.log(response);
        localStorage.setItem('IncidenT1', JSON.stringify(that.zoneData));
        that.spanView = false;
        Swal.fire('success', "Les infos de la zone ont été mis à jour!", "success");
      } catch (error) {
        that.spanView = false;
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de la mise à jour",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async getAllIncidents() {
      const that = this;
      try {
        const response = await axios.get('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/',
          {
            headers: {
              'Authorization': localStorage.getItem('token'),
            },
          });
        that.incidents = response.data.map(
          (incident) => {
            return {
              id: incident.id,
              lat: incident.location?.coordinates[0],
              lng: incident.location?.coordinates[1],
              typInci: incident.type?.id
            };
          }
        );
        that.filter(that.zoneData.typeincident);
      } catch (error) {
        console.log(error);
      }
    },
    async filter(inciType) {
      this.filterIncidents = await this.incidents.filter((incident) => {
        return (
          incident.typInci === inciType
        );
      });

      this.heatmapLayer = L.heatLayer(
        this.filterIncidents.map(point => [point.lat, point.lng, 10]),
        { radius: 20, maxZoom: 18 }
      ).addTo(this.map);
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.showModal = false;
        this.params = {};
      }
      this.submitted = false;
    },
  },
  mounted() {
    this.getZoneDetails();
    this.getAllIncidents();
    this.initializeMap();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12 d-none d-lg-block">
        <button class="btn btn-primary float-end" @click="$router.push('/add-zone')">
          <i class="bx bx-plus-circle align-middle me-1"></i> Enregistrer une zone
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless align-middle">
              <thead>
                <tr>
                  <th>Nom :</th>
                  <td scope="col">
                    <input type="text" @input="showBtn = false" class="form-control" v-model="zoneData.name">
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Message :</th>
                  <td>
                    <input type="text" @input="showBtn = false" class="form-control" v-model="zoneData.message">
                  </td>
                </tr>
              </tbody>
              <button class="btn btn-success" @click="updateZone()" :disabled="showBtn">Modifier
                <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
              </button>
            </table>
            <div class="row mb-4">
              <div class="col-lg-12">
                <div id="map" style="height: 600px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </Layout>
</template>
